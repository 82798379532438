import HeaderContainer_BlankScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/BaseScreen/BlankScreen.skin';


const HeaderContainer_BlankScreen = {
  component: HeaderContainer_BlankScreenComponent
};


export const components = {
  ['HeaderContainer_BlankScreen']: HeaderContainer_BlankScreen
};


// temporary export
export const version = "1.0.0"
